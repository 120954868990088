<template>
  <a-modal
    :title="title"
    :visible="visible"
    @ok="handleOk"
    @cancel="closeModal"
    width="600px"
  >
    <a-form-model :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
      <a-form-model-item label="账号">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="密码">
        <a-input v-model="form.desc" />
      </a-form-model-item>
      <a-form-model-item label="查询次数">
        <a-input v-model="form.desc" />
      </a-form-model-item>
      <a-form-model-item label="用途">
        <a-select
          :default-value="0"
          v-model="is_stars"
          style="width: 200px"
        >
          <a-select-option :value="0"> 全部</a-select-option>
          <a-select-option :value="1"> 人工查询</a-select-option>
          <a-select-option :value="2"> 爬虫查询</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  name: "role",
  components: {
  },
  props:{
    visible:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      title:'新增',
      form:{}
    }
  },
  computed: {
  },
  mounted(){

  },
  methods: {
    closeModal(){
      this.$emit('update:visible',false)
    },
    handleOk(){

    }
  }
};
</script>

<style lang="less" scoped>
.star-container {
  width: 100%;

}
</style>

