const columns = [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id'
  },
  {
    title: '账号',
    key: 'title',
    dataIndex: 'title'
  },
  {
    title: '密码',
    key: 'content',
    scopedSlots: { customRender: "pwd" },
  },
  {
    title: '查询次数',
    key: 'created_at',
    dataIndex: 'created_at'
  },
  {
    title: '当日剩余次数',
    key: 'created_at',
    dataIndex: 'created_at'
  },
  {
    title: '用途',
    key: 'created_at',
    dataIndex: 'created_at'
  },
  {
    title: '状态',
    key: 'created_at',
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    width: '100px',
    scopedSlots: { customRender: "action" },
  },
];

export default columns;
